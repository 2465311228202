
<div
    :class="article.size + ' ' + article.variant + ' media-hover'"
    v-view.once="emitArticleViewEvent">
  <div class="image-container">
    <a :href="article.link" @click="emitClickEvent">
      <slot :name="getArticleOrder"/>
    </a>
  </div>
  <div class="text-container">
      <slot :name="`textContainerHead-${getArticleOrder}`"></slot>
    <a :href="article.link" v-if="article.articleSkin || article.titleTeaser" @click="emitClickEvent">
      <div class="teaser-container" v-if="article.articleSkin">
        <typography
            class="skin"
            :variant="`${article.fontType ? article.fontType.titleTeaser : 'kicker'}`">
          {{ article.articleSkin }}
        </typography>
        <typography
            v-if="article.titleTeaser"
            class="title-teaser"
            :variant="`${article.fontType ? article.fontType.titleTeaser : 'kicker'}`">
          {{ article.titleTeaser }}
        </typography>
      </div>
      <typography
          v-else
          class="title-teaser"
          :variant="`${article.fontType ? article.fontType.titleTeaser : 'kicker'}`">
        <template v-if="article.titleTeaser">
            <slot :name="`placeholderTitleTeaser-${getArticleOrder}`">
              <span>{{ article.titleTeaser }}</span>
          </slot>
        </template>
      </typography>
    </a>
    <a v-if="article.author && article.author.link" :href="article.author.link">
      <typography
        class="title-teaser"
        variant="kicker"
        v-if="article.author && article.author.name"
      >
        {{ article.author.name }}
      </typography>
    </a>
    <a :href="article.link" @click="emitClickEvent">
      <typography
          class="title"
          :variant="`${article.fontType ? article.fontType.title : 'h5'}`"
          v-html="article.title"/>
    </a>
    <a v-if="article.content" :href="article.link" @click="emitClickEvent">
      <typography
          class="description"
          :variant="`${article.fontType ? article.fontType.content : 'ingress'}`"
          v-html="article.content"/>
    </a>
      <topic-teaser v-if="(article.topic && article.topic.name) || (article && article.paid) || article.updatedtime" class="teaser">
        <a v-if="article.topic && article.topic.name" :href="article.topic.link">
            {{ article.topic.name.toUpperCase() }}
        </a>
        <label
          class="uppercase"
          v-if="article && article.paid"
          >
          Abonnent
        </label>
        <li v-if="article.updatedtime">{{ article.updatedtime }}</li>
      </topic-teaser>
  </div>
</div>
